.game-container {
  max-width: 455px;
  overflow-x: hidden;
}

.game-bg {
  min-height: 100vh;
  background-image: url('../img/main-bg.png');
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
}




/* Header */
.header-section {
  padding: 15px 10px;
  background-color: transparent;
}

.header-logo {
  width: 130px;
}
/* @media (max-width: 575px) {
  .header-logo {
    width: 160px;
  }
} */

.menu-icon {
  margin-top: 5px;
  width: 40px;
}

.header-profile {
  width: 25px;
  border-radius: 50%;
  /* margin-top: 10px; */
}

.header-username {
  font-size: 12px;
}


/* Login Page */
.login-header {
  width: 80%;
}

.login-sub-heading {
  font-family: "Moon" !important;
  color: #FDC300;
  font-size: 18px;
}

.login-button {
  width: 70%;
}
.login-button:hover {
  scale: 1.02;
  transition: 0.8s;
}

.login-googoo {
  width: 100%;
  margin-left: -10%;
}



/* Check In Page */
.checkin-header {
  width: 55%;
}

.timer-padding {
  padding: 0px 22%;
}

.countdown-timer {
  border: 4px solid #ffde6f;
  -webkit-box-shadow: 0 0 10px #FDC300;
  box-shadow: 0 0 10px #FDC300;
  border-radius: 30px;
  line-height: 50px;
  font-family: 'Digital7' !important;
  font-size: 42px;
}

.checkin-googoo {
  width: 70%;
}

.short-text {
  font-family: "Moon" !important;
}

.checkin-btn-cont-padding {
  padding: 0px 40px;
}
/* @media (max-width: 399px) {
  .checkin-btn-cont-padding {
    padding: 0px 50px;
  }
} */

.checkin-btn:hover {
  scale: 1.02;
  transition: 0.8s;
}

.checked-tick {
  margin-top: -10px;
  width: 40px;
}

.claim-modal {
  background-color: #000000f4;
  border: 2px solid #FDC300;
  border-radius: 10px;
}

.earned-points {
  font-size: 64px;
  font-family: "Moon";
}

.earned-points-text {
  font-size: 20px;
  font-family: "Moon";
}

.booster-points-text {
  font-size: 14px;
  font-family: "Moon";
}

.final-points {
  font-size: 28px;
}

.claim-btn {
  width: 100%;
  max-width: 280px;
}





/* Booster */
.booster-container {
  /* background-color: pink; */
  width: 100%;
  /* height: 150px;
  background-image: url('../img/booster-box.png');
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain; */
  border: 1px solid #FDC300;
  border-radius: 5px;
  padding: 5px 15px;
  background-color: #00000090;
}

.booster-img {
  width: 100%;
  max-width: 80px;
}

.booster-counter {
  padding-top: 5px;
  font-size: 28px;
  border: 2px solid #FDC300;
  border-radius: 30px;
  line-height: 30px;
}

.booster-left {
  font-size: 10px;
}

.get-booster-btn {
  width: 60px;
}





.referral-heading {
  width: 70%;
}

.referral-btn {
  width: 35px;
}

.referral-link-box {
  background-color: white;
  border: none;
  border-radius: 30px;
  padding: 3px 15px;
}

.referral-link {
  font-size: 14px;
  font-family: "Gilroy" !important;
  font-weight: 700;
  margin-top: 5px;
}



.ranking-box-padding {
  padding: 0px 60px;
}
@media (max-width: 399px) {
  .ranking-box-padding {
    padding: 0px 30px;
  }
}

.user-container {
  width: 100%;
  border: 1px solid #FDC300;
  border-radius: 5px;
  padding: 15px;
  background-color: #00000090;
  margin-top: 30px;
}

.user-img {
  width: 100%;
  max-width: 60px;
  margin-top: -30%;
  border-radius: 50%;
}

.user-name {
  font-size: 10px;
}

.ranking-box {
  border: 2px solid #ffde6f;
  -webkit-box-shadow: 0 0 10px #FDC300;
  box-shadow: 0 0 10px #FDC300;
  border-radius: 30px;
  padding: 5px;
}

.ranking-header {
  font-family: "Gilroy";
  font-size: 11px;
  font-weight: 700;
}

.ranking-details {
  font-family: "Gilroy";
  font-size: 12px;
  font-weight: 700;
}



.leaderboard-container {
  background-color: #000000a7;
  border: 1px solid #FDC300;
  border-radius: 5px;
  overflow-x: auto;
}

thead {
  background-color: #4a9aa3;
}

.round-border-top-left {
  border-top-left-radius: 4px;
}

.round-border-top-right {
  border-top-right-radius: 4px;
}

.borderless th, .borderless td {
  border: none;
}

.borderless td {
  border-bottom: 1px solid #fdc2005a;
  /* border-right: 1px solid #fdc2005a; */
}


.leaderboard-header, .leaderboard-data {
  font-size: 11px;
}
